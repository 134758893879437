const defaultStyles = {
    red: { fill: '#d12727', opacity: 0.4 },
    altRed: { fill: '#e5412a', opacity: 0.4 },
    blue: { fill: '#296b9a', opacity: 0.4 },
    altBlue: { fill: '#397db2', opacity: 0.4 },
    green: { fill: '#009378', opacity: 0.4 },
    altGreen: { fill: '#00b695', opacity: 0.4 },
    circle: { fill: '#fff' },
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection:'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'whitesmoke',
      opacity:"0.92",
      zIndex: 9999,
    },
    content: {
      textAlign: 'center',
    },
    svg: {
      display: 'block',
      width: '150px',
      height: '150px',
    },
  };
  
  export default defaultStyles;
  