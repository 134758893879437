// import React, { createContext, useState, useContext } from 'react';

// interface LoaderContextType {
//   loading: boolean;
//   setLoading: (loading: boolean) => void;
// }

// export const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

// export const LoaderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
//   const [loading, setLoading] = useState<boolean>(false);

//   return (
//     <LoaderContext.Provider value={{ loading, setLoading }}>
//       {children}
//     </LoaderContext.Provider>
//   );
// };

// export const useLoader = () => {
//   const context = useContext(LoaderContext);
//   if (!context) {
//     throw new Error('useLoader must be used within a LoaderProvider');
//   }
//   return context;
// };


import React, { createContext, useState, ReactNode, useContext } from 'react';

interface LoaderContextType {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

export const useLoader = (): LoaderContextType => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }
  return context;
};

export const LoaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoaderContext.Provider>
  );
};


